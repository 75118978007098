<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card dense>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Lieferschein bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="12" xs="12">
                <v-card dense>
                  <v-card-text>
                    <v-form
                      name="deliveryform"
                      @submit.prevent="save"
                      v-model="formValid"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <h2>
                              Lieferschein Details
                              <v-chip>Status: {{ deliveryStatus }}</v-chip>
                            </h2>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xs="12" sm="6">
                            <v-row>
                              <v-col cols="12" xs="12">
                                <ContactSelectbox
                                  dense
                                  color="purple"
                                  v-model="delivery.customer"
                                  required
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-text-field
                                  label="Adresse"
                                  name="adresse"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="delivery.customer.adresse"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-select
                                  label="Land"
                                  name="land"
                                  :items="countryList"
                                  item-text="nameDE"
                                  item-value="code"
                                  color="purple"
                                  :clearable="true"
                                  v-model="delivery.customer.land"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" xs="12" sm="4">
                                <v-text-field
                                  label="PLZ"
                                  name="plz"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="delivery.customer.plz"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" sm="8">
                                <v-text-field
                                  label="Ort"
                                  name="ort"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="delivery.customer.ort"
                                  required
                                  dense
                                  :rules="requiredRule"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-row>
                              <v-col cols="12" xs="12">
                                <v-alert
                                  v-if="!mandant"
                                  dense
                                  border="left"
                                  type="warning"
                                >
                                  Bitte einen Mandanten auswählen!
                                </v-alert>
                                <v-text-field
                                  label="Lieferschein Nr"
                                  name="deliverynr"
                                  type="text"
                                  color="purple"
                                  :clearable="true"
                                  v-model="delivery.deliverynr"
                                  required
                                  dense
                                  :rules="requiredRule"
                                  :disabled="!mandant"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <MenuDatePicker
                                  v-model="delivery.date"
                                  name="date"
                                  label="Lieferdatum"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-divider />
                        <h2>Produkte</h2>

                        <v-row dense>
                          <v-col cols="12" dense>
                            <draggable
                              v-model="delivery.products"
                              v-bind="dragOptions"
                              class="products-list"
                              handle=".handle"
                            >
                              <transition-group>
                                <v-row
                                  dense
                                  v-for="(item, index) in deliveryProducts"
                                  :key="index"
                                >
                                  <v-col
                                    dense
                                    cols="12"
                                    sm="1"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-icon
                                      justify="center"
                                      class="handle"
                                      style="margin-right: 10px"
                                      >mdi-menu</v-icon
                                    >
                                  </v-col>
                                  <v-col dense cols="12" sm="10">
                                    <ProductFormItem
                                      dense
                                      :item="item"
                                      @fireUSTchange="calcMwst"
                                    />
                                  </v-col>
                                  <v-col dense cols="12" sm="1">
                                    <v-icon
                                      style="margin-right: 10px"
                                      @click="removeProduct(index)"
                                      >mdi-delete</v-icon
                                    >
                                  </v-col>
                                </v-row>
                              </transition-group>
                            </draggable>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn @click="addProduct">Zeile hinzufügen</v-btn>
                          </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                          <v-col cols="12" align="right">
                            <h4>
                              Gesamtsumme Netto: €
                              <span class="sum">{{ totalNetto }}</span>
                            </h4>
                            <div
                              v-for="(item, index) in delivery.mwstList"
                              :key="index"
                            >
                              <h4>
                                Mwst. {{ item.ust }}% €
                                <span class="sum">{{
                                  Number(item.sum).toFixed(2)
                                }}</span>
                              </h4>
                            </div>

                            <h4>
                              Gesamtsumme Brutto: €
                              <span class="sum">{{ totalBrutto }}</span>
                            </h4>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-col cols="12" sm="3">
                      <v-btn
                        :to="{ path: '/deliveries' }"
                        color="error"
                        type="button"
                        name="cancel"
                        >Abbrechen</v-btn
                      >
                    </v-col>

                    <v-col cols="12" sm="9" class="text-right">
                      <v-btn
                        v-if="!!isEntwurf"
                        class="ma-2"
                        @click="saveDraft"
                        color="success"
                        name="save"
                        type="button"
                        outlined
                        :disabled="!formValid"
                        >Entwurf Speichern</v-btn
                      >

                      <v-btn
                        class="ma-2"
                        @click="saveCompleted"
                        color="success"
                        name="complete"
                        type="button"
                        :disabled="!formValid"
                        >Speichern</v-btn
                      >

                      <v-menu dense offset-y align="right">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dense
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!formValid"
                            ><v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            @click="sendmail"
                            v-if="!!isEntwurf || !!isCompleted || !!isDelivered"
                            >Lieferschein senden</v-list-item
                          >
                          <v-list-item
                            @click="createPDF"
                            v-if="!!isEntwurf || !!isCompleted || !!isDelivered"
                            >PDF anzeigen</v-list-item
                          >
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MailPreviewDialog ref="mailDialog" />
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import DataService from "@/services/DeliveryService.js";
import ContactSelectbox from "@/components/contacts/ContactSelectbox";
import ProductFormItem from "@/components/products/ProductFormItem";
import draggable from "vuedraggable";
import moment from "moment";
import itemStatus from "@/helpers/itemStatus.js";
import MailPreviewDialog from "@/components/MailPreviewDialog";
import MenuDatePicker from "@/components/MenuDatePicker";

export default {
  name: "deliveryform",
  components: {
    ContactSelectbox,
    ProductFormItem,
    draggable,
    MailPreviewDialog,
    MenuDatePicker,
  },
  data: () => ({
    dateMenu: false,

    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid",
    ],
    formValid: true,
  }),

  methods: {
    sendmail() {
      this.$refs.mailDialog.setDataItem(this.delivery);
      this.$refs.mailDialog.showDialog();
    },
    setNetto(price) {
      this.delivery.totalNetto = price;
    },
    setBrutto(price) {
      this.delivery.totalBrutto = price;
    },
    calcMwst() {
      let mwstList = [];

      for (let index in this.deliveryProducts) {
        let item = this.deliveryProducts[index];

        if (!item.ust || !item.totalSum) {
          continue;
        }
        let mwst =
          Number.parseFloat(item.totalSum) * (Number.parseInt(item.ust) / 100);

        let mwstItem = { ust: item.ust, sum: 0 };
        let mwstIndex = 0;
        let found = false;

        for (let i in mwstList) {
          if (mwstList[i].ust == item.ust) {
            mwstItem = mwstList[i];
            mwstIndex = i;
            found = true;
            break;
          }
        }

        mwstItem.sum = Number.parseFloat(
          Number(mwst + mwstItem.sum).toFixed(2)
        );

        if (found) {
          mwstList[mwstIndex] = mwstItem;
        } else {
          mwstList.push(mwstItem);
        }
      }
      this.delivery.mwstList = mwstList;
    },
    addProduct() {
      this.$store.dispatch("delivery/addProduct", {
        _id: null,
        productnr: null,
        name: "",
        description: "",
        productlink: "",
        quantity: 1,
        netto: 0,
        ust: 20,
        unit: "",
        totalSum: 0,
      });
      //this.delivery.products.push();
    },
    removeProduct(index) {
      this.$store.dispatch("delivery/removeProduct", index).then(() => {
        this.calcMwst();
      });
    },

    createPDF() {
      let pdfItem = this.delivery;

      pdfItem.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
      pdfItem.mandantData = this.$store.getters[
        "mandant/getMandantenList"
      ].find((item) => item._id == this.delivery.mandant);
      pdfItem.createTS = moment();
      DataService.makePdf(pdfItem)
        .then((response) => {
          let FileSaver = require("file-saver");
          let filename = "Lieferschein-" + this.delivery.deliverynr + ".pdf";
          FileSaver.saveAs(
            new Blob([response.data], {
              type: "application/pdf;base64;",
            }),
            filename
          );
          console.log("success");
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message,
          });
        });
    },

    loadDeliveryNr() {
      if (!this.delivery.mandant || this.delivery.mandant == "all") {
        this.delivery.delivernr == "";
        return false;
      } else {
        //this.formValid = true;
      }

      this.$store
        .dispatch("delivery/getNextNr", this.delivery.mandant)
        .then((result) => {
          if (result) {
            this.delivery.lfdnr = result.lfdnr;
            this.delivery.deliverynr = result.item;
          }
        });
    },

    deliver() {
      this.delivery.status = itemStatus.Delivered.code;
      this.save();
    },
    saveDraft() {
      this.delivery.status = itemStatus.Draft.code;
      this.save();
    },
    saveCompleted() {
      this.delivery.status = itemStatus.Completed.code;
      this.save();
    },
    saveDelivered() {
      this.delivery.status = itemStatus.Delivered.code;
      this.save();
    },

    save() {
      this.$store.commit("SET_APP_LOADING", true);
      this.delivery.userKuerzel = this.$store.getters["user/getUser"].kuerzel;
      this.delivery.mandantData = this.mandant;
      this.delivery.createTS = moment();
      this.delivery.templateID =
        this.delivery.mandantData.pdfTemplates.deliveryTemplate;
      this.$store
        .dispatch("delivery/saveDelivery", this.delivery)
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "deliveries" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Lieferschein gespeichert",
            text: "Lieferschein erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
  },
  computed: {
    deliveryStatus() {
      let status = "Neu";

      for (const key of Object.keys(itemStatus)) {
        if (itemStatus[key].code == this.delivery.status) {
          status = itemStatus[key].title;
        }
      }

      return status;
    },
    isEntwurf() {
      return this.delivery.status == itemStatus.Draft.code;
    },

    isCompleted() {
      return this.delivery.status == itemStatus.Completed.code;
    },
    isDelivered() {
      return this.delivery.status == itemStatus.Delivered.code;
    },

    deliveryProducts() {
      const p = this.$store.getters["delivery/getCurrent"];
      if (p) {
        return p.products;
      } else {
        return [];
      }
    },
    delivery() {
      return this.$store.getters["delivery/getCurrent"];
    },
    mandant() {
      return this.$store.getters["mandant/getWorkingMandantData"];
    },
    totalNetto() {
      let sum = 0;
      this.deliveryProducts.forEach((item) => {
        sum += Number.parseFloat(item.totalSum);
      });
      this.calcMwst();
      this.setNetto(sum);

      return Number(sum).toFixed(2);
    },

    totalBrutto() {
      let netto = this.totalNetto;
      let sum = netto;
      this.delivery.mwstList.forEach((item) => {
        sum = Number.parseFloat(item.sum) + Number.parseFloat(sum);
      });
      this.setBrutto(sum);
      return Number(sum).toFixed(2);
    },

    dragOptions() {
      return {
        animation: 0,
        group: "",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    countryList() {
      return this.$store.getters["countries/getCountryList"];
    },
  },
  created() {
    let id = this.$route.params.id;
    if (!id) {
      this.$store.dispatch("delivery/newDelivery").then(() => {
        this.delivery.date = moment().format("YYYY-MM-DD");

        this.delivery.mandant =
          this.$store.getters["mandant/getWorkingMandant"];
        this.loadDeliveryNr();
      });
    } else {
      this.$store
        .dispatch("delivery/fetchDelivery", {
          id: id,
          mandant: this.$store.getters["mandant/getWorkingMandant"],
        })
        .then(() => {})
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    }
    eventBus.$on("mandantChanged", (data) => {
      if (data == "all") {
        this.delivery.deliverynr = "";
      } else {
        this.delivery.mandant = data;
        this.loadDeliveryNr();
      }
    });
  },
  beforeDestroy() {
    this.$store.dispatch("delivery/resetDelivery");
  },
};
</script>
<style scoped>
.sum {
  margin-left: 20px;
}

.ghost {
  opacity: 0.5;
  background-color: #ccc;
}

.handle:hover {
  cursor: move;
}
</style>
